import {
  ComponentProps,
  forwardRef,
  PropsWithChildren,
  useEffect,
  useRef,
} from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import {
  AnimatePresence,
  AnimationSequence,
  motion,
  useAnimate,
  Variants,
} from 'framer-motion';
import { Icon } from 'icons';
import Markdown from 'react-markdown';

import {
  Dropdown,
  StarPointyVaiDiagonal,
  VaiColored,
} from '@visualist/design-system/src/components/v2';

import { Conversation, VaiUserConversation } from '@api/vai';
import { Spinner } from '@components/Spinner';
import { VaiBeacon } from '@src/entities/vai/ui/beacon';
import { VaiButton, VaiButtonText } from '@src/entities/vai/ui/button';
import { usePrevious } from '@src/shared/hooks/usePrevious';

import { useConversations } from '../../hooks/useConversations';
import { useVaiActionProgress, useVaiActions } from '../../hooks/useVaiActions';
import { $vaiModalSelectedMessage, scrolledMessageIntoView } from '../../model';
import { EmptyScreen } from '../vai-chat';

import styles from './styles.module.css';

export const Conversations = ({ hubId }: { hubId: string }) => {
  const [vaiModalSelectedMessage] = useUnit([$vaiModalSelectedMessage]);
  const containerRef = useRef<HTMLDivElement>(null);
  const shouldSmoothScroll = useRef(false);
  const { conversationQuery } = useConversations(hubId);
  const previousHubId = usePrevious(hubId);

  const isVaiActionInProgress = useVaiActionProgress(hubId);

  useEffect(() => {
    if (vaiModalSelectedMessage) {
      const messageElement = document.querySelector(
        `[data-message-id="${vaiModalSelectedMessage}"]`,
      );
      if (messageElement) {
        setTimeout(() => {
          messageElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 120);
        scrolledMessageIntoView();
      }
    }
  }, [vaiModalSelectedMessage]);

  useEffect(() => {
    if (hubId !== previousHubId) shouldSmoothScroll.current = false;

    if (vaiModalSelectedMessage) return;

    if (containerRef.current) {
      if (!shouldSmoothScroll.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
        shouldSmoothScroll.current = true;
      } else {
        setTimeout(() => {
          if (containerRef.current)
            containerRef.current.scrollTo({
              top: containerRef.current.scrollHeight,
              behavior: 'smooth',
            });
          // Scroll after animation finishes
        }, 400);
      }
    }
  }, [
    conversationQuery.data?.conversations.length,
    isVaiActionInProgress,
    hubId,
  ]);

  // For when vai is thinking and no messages are there
  if (
    isVaiActionInProgress &&
    !conversationQuery.isLoading &&
    conversationQuery.data?.conversations.length === 0
  ) {
    return (
      <div className={cn(styles.container, styles.conversations)}>
        <VaiTypingAnimation variants={{}} />
      </div>
    );
  }

  // If the chat is loading the data
  if (conversationQuery.isLoading) {
    return (
      <div className={cn(styles.container, styles.conversations)}>
        <Spinner />
      </div>
    );
  }

  if (conversationQuery.data?.conversations.length === 0) {
    return <EmptyScreen selectedHubId={hubId} />;
  }

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.8,
        delay: 0.5,
      },
    },
  } satisfies Variants;

  const item = {
    hidden: {
      opacity: 0,
      filter: 'blur(4px)',
      scale: 0.98,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      opacity: 1,
      filter: 'blur(0px)',
      scale: 1,
      transition: {
        duration: 0.4,
      },
    },
    exit: {
      opacity: 0,
      scale: 0,
    },
  } satisfies Variants;

  return (
    <motion.div
      ref={containerRef}
      variants={container}
      initial={conversationQuery.isFetched ? 'hidden' : undefined}
      animate="show"
      className={cn(styles.container, styles.conversations)}
    >
      {conversationQuery.data?.conversations.map((c, idx, array) => {
        return (
          <MessageRenderer
            initial={conversationQuery.isFetched ? 'hidden' : undefined}
            animate="show"
            variants={item}
            message={c}
            isLastMessage={idx === array.length - 1}
            hubId={hubId}
            key={c.id}
          />
        );
      })}
      {/* Render type animation if data is present and mutation is running */}
      <AnimatePresence mode="popLayout">
        {isVaiActionInProgress ? <VaiTypingAnimation variants={item} /> : null}
      </AnimatePresence>
    </motion.div>
  );
};

export const MessageRenderer = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof motion.div> & {
    message: Conversation;
    hubId: string;
    isLastMessage: boolean;
  }
>(({ message, hubId, isLastMessage, ...rest }, ref) => {
  switch (message.convo_type.id) {
    case '0':
    case '1':
    case '2':
      if ('text' in message) {
        {
          /* TODO REFACTOR PLSSSSSS */
        }
        if (message.text.text.includes('action_items')) {
          const actionItems: {
            action_items: {
              name: string;
              priority: string;
              assignee: string;
              due_date: string;
              id: string;
            }[];
          } = JSON.parse(message.text.text);
          return (
            <>
              {actionItems.action_items.map((a) => (
                <VaiMessage
                  key={a.id}
                  message={message}
                  hubId={hubId}
                  isLastMessage={false}
                  showPulsingBeacon
                >
                  <div
                    className={cn(styles.actionItems, styles.text)}
                    key={a.id}
                  >
                    <Icon name="sprite/tick" size={24} />
                    <p>{a.name}</p>
                  </div>
                </VaiMessage>
              ))}
            </>
          );
        }
        return (
          <VaiMessage
            ref={ref}
            message={message}
            hubId={hubId}
            isLastMessage={isLastMessage}
            showPulsingBeacon={false}
            {...rest}
          >
            <Markdown className={styles.text}>{message.text.text}</Markdown>
          </VaiMessage>
        );
      }
      return <UndefinedMessage ref={ref} message={message} {...rest} />;
    case '3':
    default:
      return <UndefinedMessage ref={ref} message={message} {...rest} />;
  }
});

const VaiMessage = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ComponentProps<typeof motion.div>> & {
    message: VaiUserConversation;
    hubId: string;
    isLastMessage: boolean;
    showPulsingBeacon: boolean;
  }
>(
  (
    { message, hubId, isLastMessage, children, showPulsingBeacon, ...rest },
    ref,
  ) => {
    const { toggleEmailPinMutation } = useVaiActions(hubId);
    const [scope, animate] = useAnimate();

    return (
      <>
        <motion.div
          ref={ref}
          className={styles.message}
          data-message-type={message.convo_type.id}
          data-message-id={message.id}
          {...rest}
        >
          {children}
          {/* REFACTOR THIS AS WELLLLLL */}
          {showPulsingBeacon ? (
            <div className={styles.vaiBeaconContainer}>
              <Dropdown>
                <Dropdown.Menu
                  density="-2"
                  trigger={<VaiBeacon />}
                  side="bottom"
                  align="end"
                >
                  <Dropdown.MenuItem
                    item={{
                      content: 'Ask Vai to',
                      leadingIcon: <VaiColored fill="none" />,
                      isDivider: true,
                      isDisabled: true,
                      classNameContent: styles.vaiDropdownHeader,
                    }}
                    style={{ cursor: 'default' }}
                  />
                  <Dropdown.MenuItem
                    item={{
                      content: 'Add to action items list',
                      onClick: () => {},
                      leadingIcon: <Icon name="sprite/action-items-list" />,
                      classNameContent: styles.noTextWrap,
                    }}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : null}
          {/* REFACTOR THIS AS WELLLLLL */}
          {
            // For Vai messages only show the pin button
            message.convo_type.id === '0' && !showPulsingBeacon ? (
              <motion.button
                ref={scope}
                style={{
                  transformOrigin: 'center',
                }}
                whileTap={{
                  scale: 0.9,
                }}
                whileHover={{
                  scale: 1.1,
                }}
                className={styles.pinStar}
                onClick={async () => {
                  const sequence = [
                    [
                      scope.current,
                      { transform: 'scale(0.7)' },
                      { duration: 0.3, type: 'spring', bounce: 0.2 },
                    ],
                    [
                      scope.current,
                      { transform: 'scale(1)' },
                      { duration: 0.2, type: 'tween', ease: 'easeOut' },
                    ],
                  ] satisfies AnimationSequence;

                  animate(sequence);

                  toggleEmailPinMutation.mutateAsync({
                    hubId,
                    conversationId: message.id,
                  });
                }}
              >
                <StarPointyVaiDiagonal
                  fill={
                    message.is_pinned
                      ? 'var(--color-wolfe-seed)'
                      : 'transparent'
                  }
                />
              </motion.button>
            ) : null
          }
        </motion.div>
        {isLastMessage ? (
          <ResponseRenderer message={message} hubId={hubId} />
        ) : null}
      </>
    );
  },
);

const ResponseRenderer = ({
  message,
  hubId,
}: {
  message: Conversation;
  hubId: string;
}) => {
  const possibleResponses = message.possible_responses;

  if (!possibleResponses || !possibleResponses.length || !('text' in message))
    return null;

  return (
    <div className={styles.responseContainer}>
      {possibleResponses.map((r) => {
        return (
          <Response
            key={r.action}
            response={r}
            hubId={hubId}
            message={message}
          />
        );
      })}
    </div>
  );
};

const Response = ({
  response,
  hubId,
  message,
}: {
  response: NonNullable<Conversation['possible_responses']>[number];
  hubId: string;
  message: VaiUserConversation;
}) => {
  const { sendMessageMutation } = useVaiActions(hubId);

  return (
    <VaiButton
      variant={response.response_text.includes('No') ? 'secondary' : 'primary'}
      onClick={() => {
        sendMessageMutation.mutate({
          hubId: hubId,
          user_action: response.action,
          vai_action: message.text.action,
          relatedObjectId: message.related_object.id,
          relatedObjectTypeId: message.related_object.type_id,
        });
      }}
    >
      <VaiButtonText>{response.response_text}</VaiButtonText>
    </VaiButton>
  );
};

export const UndefinedMessage = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof motion.div> & {
    message: Conversation;
  }
>(({ message, ...rest }, ref) => {
  return (
    <motion.div
      ref={ref}
      className={styles.message}
      data-message-type={message.convo_type.id}
      data-message-id={message.id}
      {...rest}
    >
      <p className={styles.text}>Undefined message</p>
    </motion.div>
  );
});

const VaiTypingAnimation = ({ variants }: { variants: Variants }) => {
  return (
    <motion.div
      variants={variants}
      initial="hide"
      animate="show"
      exit="exit"
      className={cn(styles.message, styles.vaiTyping)}
      data-message-type="0"
    >
      <div className={styles.animationRow}>
        <svg
          width="6"
          height="6"
          viewBox="0 0 6 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 5C1.89543 5 1 4.10457 1 3.00001C1 1.89544 1.89543 1 3 1C4.10457 1 5 1.89544 5 3.00001C5 4.10457 4.10457 5 3 5Z"
            stroke="url(#paint0_linear_2052_3019)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2052_3019"
              x1="5"
              y1="1"
              x2="0.358412"
              y2="3.96876"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#630682" />
              <stop offset="0.5" stopColor="#8B39A8" />
              <stop offset="1" stopColor="#FAD7FF" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          width="6"
          height="6"
          viewBox="0 0 6 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 5C1.89543 5 1 4.10457 1 3.00001C1 1.89544 1.89543 1 3 1C4.10457 1 5 1.89544 5 3.00001C5 4.10457 4.10457 5 3 5Z"
            stroke="url(#paint0_linear_2052_3020)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2052_3020"
              x1="5"
              y1="1"
              x2="0.358412"
              y2="3.96876"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#630682" />
              <stop offset="0.5" stopColor="#8B39A8" />
              <stop offset="1" stopColor="#FAD7FF" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          width="6"
          height="6"
          viewBox="0 0 6 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5 1H1V5H5V1Z" stroke="url(#paint0_linear_2052_3021)" />
          <defs>
            <linearGradient
              id="paint0_linear_2052_3021"
              x1="5"
              y1="1"
              x2="0.358412"
              y2="3.96876"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#630682" />
              <stop offset="0.5" stopColor="#8B39A8" />
              <stop offset="1" stopColor="#FAD7FF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </motion.div>
  );
};
