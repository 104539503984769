import { useUnit } from 'effector-react';

import {
  FilterChip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { HubSearch } from '@src/entities/search-modal/hub-search';
import { VaiButton } from '@src/entities/vai/ui/button';

import { $selectedHubWorkspace, changedVaiHubWorkspace } from '../../model';

import styles from './styles.module.css';

export const VaiHubSwitcher = () => {
  const [selectedHubWorkspace] = useUnit([$selectedHubWorkspace]);

  return (
    <div className={styles.container}>
      {selectedHubWorkspace === null ? (
        <TypographyPoppins type="title" titleSize="M" className={styles.text}>
          Vai is working generally
        </TypographyPoppins>
      ) : (
        <div className={styles.workinInHubContainer}>
          <TypographyPoppins type="title" titleSize="M" className={styles.text}>
            Working in
          </TypographyPoppins>
          <HubSearch
            selectedHub={selectedHubWorkspace}
            onResultClick={(hub) => {
              changedVaiHubWorkspace({
                id: hub.id,
                name: hub.name,
              });
            }}
          >
            <FilterChip
              hideTick
              type="label"
              labelSize="L"
              isSelected
              className={styles.hubName}
            >
              {selectedHubWorkspace.name}
            </FilterChip>
          </HubSearch>
        </div>
      )}

      {selectedHubWorkspace === null ? (
        <HubSearch
          selectedHub={selectedHubWorkspace}
          onResultClick={(hub) => {
            changedVaiHubWorkspace({
              id: hub.id,
              name: hub.name,
            });
          }}
        >
          <VaiButton variant="ghost">Go to a hub</VaiButton>
        </HubSearch>
      ) : (
        <VaiButton variant="ghost" onClick={() => changedVaiHubWorkspace(null)}>
          Exit hub
        </VaiButton>
      )}
    </div>
  );
};
