import { Fragment, useMemo } from 'react';

import cn from 'classnames';
import { Icon } from 'icons';
import { useHistory, useParams } from 'react-router';

import {
  Avatar,
  Divider,
  Spinner,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { Thread } from '@api/messaging';
import { useMessages } from '@pages/messages/hooks/useMessages';
import {
  constructMessagesByDate,
  convertEmailDate,
  formatName,
  formatSenderToReturnNameOrEmail,
  SortedMessageLabels,
  sortMessagesByDate,
} from '@pages/messages/utils';

import { EmailHubButton } from '../email-hub-button';

import styles from './styles.module.css';

export const EmailList = ({ hubId }: { hubId: string }) => {
  const {
    messages,
    query: { isLoading, isFetched },
  } = useMessages({ hubId });

  if (isLoading) {
    return (
      <div className={styles.noEmailContainer}>
        <Spinner />
      </div>
    );
  }

  if (isFetched && messages && messages.length === 0) {
    return (
      <div className={styles.noEmailContainer}>
        <div className={styles.noEmailInnerContainer}>
          <Icon
            name="sprite/sticky-note-soft-colored"
            size={80}
            className={styles.noEmailIcon}
          />
          <TypographyPoppins
            type="body"
            bodySize="S"
            className={styles.noEmailText}
          >
            No messages here. Time to get busy!
          </TypographyPoppins>
          <EmailHubButton hubId={hubId} />
        </div>
      </div>
    );
  }

  if (!messages) return null;

  return (
    <>
      <Emails messages={messages} hubId={hubId} />
      <EmailHubButton className={styles.emailHubButton} hubId={hubId} />
    </>
  );
};

const Emails = ({ messages, hubId }: { hubId: string; messages: Thread[] }) => {
  const history = useHistory();
  const { threadId } = useParams<{ hubId?: string; threadId?: string }>();

  const messagesSortedByDate = useMemo(
    () => constructMessagesByDate(messages, new Date()),
    [messages],
  );

  // Casted with as because Object.keys returns a string[]
  const keys = useMemo(
    () => Object.keys(messagesSortedByDate),
    [messagesSortedByDate],
  ) as (keyof typeof messagesSortedByDate)[];

  const openEmail = (t: Thread) => {
    history.push(`/messages/${hubId}/${t.id}`);
  };

  return (
    <ul className={styles.emailListContainer}>
      {keys.map((key) => {
        if (key === 'remainingDaysInWeek') {
          // Filter out days with no messages
          return Object.keys(messagesSortedByDate[key])
            .filter((v) => {
              const day =
                v as keyof (typeof messagesSortedByDate)['remainingDaysInWeek'];
              const messages = messagesSortedByDate['remainingDaysInWeek'][day];
              return messages.length;
            })
            .map((day) => {
              const castedDay =
                day as keyof (typeof messagesSortedByDate)['remainingDaysInWeek'];
              const messages =
                messagesSortedByDate['remainingDaysInWeek'][castedDay];

              return (
                <Fragment key={day}>
                  <div className={styles.emailListDateSeperator}>
                    <TypographyPoppins type="label" labelSize="M">
                      {day}
                    </TypographyPoppins>
                  </div>
                  {messages.sort(sortMessagesByDate).map((t) => (
                    <EmailListItem
                      key={t.id}
                      message={t}
                      isSelected={threadId === t.id}
                      onClick={() => openEmail(t)}
                    />
                  ))}
                </Fragment>
              );
            });
        } else {
          //TODO fix Type cast. For now its fine as we filtered out the remainingDaysInWeek above
          const messages = messagesSortedByDate[key] as Thread[];

          if (messages.length === 0) return null;

          return (
            <Fragment key={key}>
              <div className={styles.emailListDateSeperator}>
                <TypographyPoppins type="label" labelSize="M">
                  {SortedMessageLabels[key]}
                </TypographyPoppins>
              </div>
              {messages.sort(sortMessagesByDate).map((t) => (
                <EmailListItem
                  key={t.id}
                  message={t}
                  isSelected={threadId === t.id}
                  onClick={() => openEmail(t)}
                />
              ))}
            </Fragment>
          );
        }
      })}
    </ul>
  );
};

export const EmailListItem = ({
  message,
  onClick,
  isSelected,
  displayDate = true,
  hideDivider = false,
  dividerReducedWidth = false,
}: {
  message: Thread;
  onClick: () => void;
  isSelected: boolean;
  displayDate?: boolean;
  hideDivider?: boolean;
  dividerReducedWidth?: boolean;
}) => {
  return (
    <li className={styles.emailListItem}>
      <button
        className={cn(styles.email, {
          [styles.selected]: isSelected,
          [styles.hideDivider]: hideDivider,
        })}
        onClick={onClick}
      >
        <div className={styles.readIndicatorContainer}>
          <div
            className={styles.readIndicator}
            style={
              {
                '--visible': message.is_read ? 'hidden' : 'visible',
              } as React.CSSProperties
            }
          ></div>
        </div>
        <div className={styles.senderImgContainer}>
          <Avatar
            size={60}
            image={message.sender.photo?.full_size}
            initials={formatSenderToReturnNameOrEmail(message.sender)}
          />
        </div>
        <div className={styles.detailsContainer}>
          <TypographyPoppins
            type="label"
            labelSize="L"
            className={styles.subject}
          >
            {message.subject}
          </TypographyPoppins>
          <TypographyPoppins
            type="label"
            labelSize="M"
            className={styles.sender}
          >
            {formatName(message.sender)}
          </TypographyPoppins>
          <div className={styles.textContainer}>
            <TypographyPoppins type="body" bodySize="S" className={styles.text}>
              {message.text}
            </TypographyPoppins>
            <div
              className={styles.threadCount}
              style={
                {
                  '--visible':
                    message.thread_count - 1 === 0 ? 'hidden' : 'visible',
                } as React.CSSProperties
              }
            >
              <TypographyPoppins
                type="label"
                labelSize="S"
                className={styles.threadCountText}
              >
                {message.thread_count}
              </TypographyPoppins>
            </div>
          </div>
        </div>
        {displayDate && (
          <TypographyPoppins
            type="label"
            labelSize="M"
            className={styles.dateText}
          >
            {convertEmailDate(message.sent_at)}
          </TypographyPoppins>
        )}
      </button>
      {!hideDivider && (
        <Divider
          type={dividerReducedWidth ? 'short-line' : 'long-line'}
          className={cn(styles.divider, {
            [styles.dividerReducedWidth]: dividerReducedWidth,
          })}
        />
      )}
    </li>
  );
};
