import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { Icon } from 'icons';
import { useHistory, useLocation } from 'react-router';

import {
  Avatar,
  Divider,
  Dropdown,
  IconButton,
  Item,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { useWindowSize } from '@visualist/hooks';

import type { Thread, Threads } from '@api/messaging';
import { useAttachment } from '@pages/messages/hooks/useAttachments';
import { useMessages } from '@pages/messages/hooks/useMessages';
import {
  formatDateTodayYesterday,
  formatFileName,
  formatName,
  formatSenderToReturnNameOrEmail,
  getFileIcon,
} from '@pages/messages/utils';
import { VaiMessageBeacon } from '@src/entities/messages/ui/vai-beacon';
import { VaiSection } from '@src/entities/messages/ui/vai-section';

import styles from './styles.module.css';

export const EmailContent = ({
  thread,
  threads,
  hubId,
  hubName,
}: {
  hubId: string;
  thread: Thread;
  threads: Thread[];
  hubName: string;
}) => {
  return (
    <div className={styles.content}>
      <Subject subject={thread.subject} hubId={hubId} />
      <VaiSection hubId={hubId} hubName={hubName} threadId={thread.id} />
      <Threads threads={threads} hubId={hubId} hubName={hubName} />
    </div>
  );
};

const Subject = ({ subject, hubId }: { subject: string; hubId: string }) => {
  const history = useHistory();
  const { isMobile } = useWindowSize();

  return (
    <div className={styles.subjectContainer}>
      {isMobile ? (
        <IconButton
          type="unfilled"
          icon={<Icon size={24} name="sprite/chevron-left" />}
          onClick={() => history.push(`/messages/${hubId}`)}
          className={styles.backButton}
        />
      ) : null}
      <div className={styles.subjectInnerContainer}>
        <TypographyPoppins
          type="label"
          labelSize="M"
          className={styles.subjectLabel}
        >
          Subject
        </TypographyPoppins>
        <TypographyPoppins
          type="title"
          titleSize={isMobile ? 'S' : 'L'}
          className={styles.subjectText}
        >
          {subject}
        </TypographyPoppins>
      </div>
    </div>
  );
};

const Threads = ({
  threads,
  hubId,
  hubName,
}: {
  threads: Thread[];
  hubId: string;
  hubName: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const threadId = queryParams.get('threadId');

  useEffect(() => {
    if (ref.current && threadId) {
      const threadElement = ref.current.querySelector(
        `[data-thread-id="${threadId}"]`,
      );
      if (threadElement) {
        threadElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, []);

  return (
    <div ref={ref} className={styles.threadsContainer}>
      {threads.map((thread) => (
        <Thread
          defaultExpandedThreadId={threadId}
          parentThreadId={threads[threads.length - 1].id}
          key={thread.id}
          thread={thread}
          hubId={hubId}
          hubName={hubName}
        />
      ))}
    </div>
  );
};

const Thread = ({
  parentThreadId,
  thread,
  hubId,
  hubName,
  defaultExpandedThreadId,
}: {
  parentThreadId: string;
  thread: Thread;
  hubId: string;
  hubName: string;
  defaultExpandedThreadId: string | null;
}) => {
  const history = useHistory();

  const [expanded, setExpanded] = useState(
    defaultExpandedThreadId === thread.id,
  );

  const { mutateMessageReadStatus } = useMessages({ hubId });

  const toggleExpanded = () => {
    if (!thread.is_read) {
      mutateMessageReadStatus.mutate({
        readStatus: true,
        threadId: thread.id,
      });
    }

    if (defaultExpandedThreadId) {
      history.replace(`/messages/${hubId}/${parentThreadId}`);
    }
    setExpanded((e) => !e);
  };

  return (
    <div
      data-thread-id={thread.id}
      className={cn(styles.threadContainer, {
        [styles.threadHighlight]:
          defaultExpandedThreadId === thread.id && expanded,
      })}
    >
      <ThreadHeader
        hubId={hubId}
        hubName={hubName}
        parentThreadId={parentThreadId}
        thread={thread}
        sentAt={thread.sent_at}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      <ThreadFrom thread={thread} isExpanded={expanded} hubId={hubId} />
      <Divider type="long-line" className={styles.divider} />
      <ThreadContent
        thread={thread}
        isExpanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      <Attachments
        attachments={thread.attachments}
        isExpanded={expanded}
        hubId={hubId}
        threadId={thread.id}
      />
    </div>
  );
};

const ThreadHeader = ({
  parentThreadId,
  expanded,
  toggleExpanded,
  sentAt,
  hubId,
  hubName,
  thread,
}: {
  parentThreadId: string;
  expanded: boolean;
  toggleExpanded: () => void;
  sentAt: string;
  hubId: string;
  hubName: string;
  thread: Thread;
}) => {
  const menuItems: Item[] = [
    {
      content: 'Copy link to email',
      leadingIcon: <Icon size={14} name="sprite/link" />,
      onClick: () => {
        try {
          navigator.clipboard.writeText(
            `${window.location.origin}/messages/${hubId}/${parentThreadId}?threadId=${thread.id}`,
          );
          startedSnack({ label: 'Copied link to email' });
        } catch (e) {
          console.error(e);
          startedSnack({ label: 'Couldn’t copy link to email' });
        }
      },
    },
  ];

  return (
    <div className={styles.threadHeader}>
      <div className={styles.threadHeaderButtons}>
        <Tooltip
          parameter={{
            type: 'plain',
            position: 'right',
            description: expanded ? 'Collapse message' : 'Expand message',
          }}
          className={styles.tooltip}
        >
          <IconButton
            icon={
              expanded ? (
                <Icon
                  size={10}
                  color="var(--color-tertiary-40)"
                  name="sprite/chevron-up"
                />
              ) : (
                <Icon
                  size={10}
                  color="var(--color-tertiary-40)"
                  name="sprite/chevron-down"
                />
              )
            }
            onClick={toggleExpanded}
            type="outlined"
            className={styles.threadButton}
          />
        </Tooltip>
      </div>
      <VaiMessageBeacon
        threadId={thread.id}
        parentThreadId={parentThreadId}
        hubId={hubId}
        hubName={hubName}
      />
      <div className={styles.threadHeaderRightSection}>
        <TypographyPoppins type="label" labelSize="M" className={styles.date}>
          {formatDateTodayYesterday(sentAt)}
        </TypographyPoppins>
        <Dropdown>
          <Dropdown.Menu
            trigger={
              <IconButton
                type="unfilled"
                onClick={() => {}}
                icon={<Icon size={12} name="sprite/3-dot-menu" />}
                className={styles.threadButton}
              />
            }
            side="bottom"
            align="end"
            sideOffset={15}
            collisionPadding={20}
            density="-2"
          >
            {menuItems.map((item, index) => (
              <Dropdown.MenuItem key={index} item={item} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

const ThreadFrom = ({
  thread,
  isExpanded,
  hubId,
}: {
  thread: Thread;
  isExpanded: boolean;
  hubId: string;
}) => {
  const { sender } = thread;
  const senderName = formatSenderToReturnNameOrEmail(sender);

  return (
    <div className={styles.fromContainer}>
      <div className={styles.from}>
        <TypographyPoppins type="label" labelSize="XS" className={styles.label}>
          From
        </TypographyPoppins>
        <div className={styles.user}>
          <Avatar
            size={20}
            image={sender.photo?.full_size}
            initials={senderName}
          />
          <TypographyPoppins type="label" labelSize="M">
            {formatName(sender)}
          </TypographyPoppins>
        </div>
      </div>
      {isExpanded ? (
        <>
          <div className={cn(styles.from, styles.to)}>
            <TypographyPoppins
              type="label"
              labelSize="XS"
              className={styles.label}
            >
              To
            </TypographyPoppins>
            <div className={styles.recipientsContainer}>
              {thread.recipients_to
                .filter((r) => !isHubEmail(r.email, hubId))
                .map((r) => (
                  <div key={r.id} className={styles.user}>
                    <Avatar
                      size={20}
                      image={r.photo?.full_size}
                      initials={formatSenderToReturnNameOrEmail(r)}
                    />
                    <TypographyPoppins type="label" labelSize="M">
                      {formatName(r)}
                    </TypographyPoppins>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.from}>
            <TypographyPoppins
              type="label"
              labelSize="XS"
              className={styles.label}
            >
              Cc
            </TypographyPoppins>
            <div className={styles.recipientsContainer}>
              {thread.recipients_cc
                .filter((r) => !isHubEmail(r.email, hubId))
                .map((r) => (
                  <div key={r.id} className={styles.user}>
                    <Avatar
                      size={20}
                      image={r.photo?.full_size}
                      initials={formatSenderToReturnNameOrEmail(r)}
                    />
                    <TypographyPoppins type="label" labelSize="M">
                      {formatName(r)}
                    </TypographyPoppins>
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const ThreadContent = ({
  thread,
  isExpanded,
  toggleExpanded,
}: {
  thread: Thread;
  isExpanded: boolean;
  toggleExpanded: () => void;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showThreadContent, setShowThreadContent] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const isContentOverflowing =
          contentRef.current.scrollHeight > contentRef.current.clientHeight;
        setIsOverflowing(isContentOverflowing);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [thread.html]);

  const parser = new DOMParser();
  const doc = parser.parseFromString(thread.html, 'text/html');
  const gmailThreadData = doc.querySelector('.gmail_quote');

  if (gmailThreadData) {
    gmailThreadData.remove();
  }

  return (
    <div
      ref={contentRef}
      className={styles.threadContent}
      data-expanded={isExpanded}
      data-overflowing={isOverflowing}
      onClick={() => {
        if (!isExpanded) {
          toggleExpanded();
        }
      }}
      style={
        {
          '--expanded-height': isExpanded ? 'auto' : '120px',
        } as React.CSSProperties
      }
    >
      <TypographyPoppins type="body" bodySize="M">
        <div dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />
        {gmailThreadData ? (
          <>
            <Tooltip
              parameter={{
                type: 'plain',
                position: 'right',
                description: showThreadContent
                  ? 'Hide trimmed content'
                  : 'Show trimmed content',
              }}
              disableWrapperCenter
              className={styles.threadExpandButtonTooltip}
              wrapperClassName={styles.threadExpandButtonTooltipWrapper}
            >
              <button
                className={styles.threadExpandButton}
                onClick={() => setShowThreadContent((c) => !c)}
              >
                <div />
                <div />
                <div />
              </button>
            </Tooltip>
            {showThreadContent ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: gmailThreadData?.innerHTML,
                }}
              />
            ) : null}
          </>
        ) : null}
      </TypographyPoppins>
    </div>
  );
};

const Attachments = ({
  attachments,
  isExpanded,
  hubId,
  threadId,
}: {
  attachments: Thread['attachments'];
  isExpanded: boolean;
  hubId: string;
  threadId: string;
}) => {
  if (!isExpanded) return null;

  const { downloadAttachment, mutateSaveAttachmentToLibrary } = useAttachment();

  return (
    <div className={styles.attachmentsContainer}>
      {attachments.map((attachment) => {
        const isImage =
          attachment.filename.includes('png') ||
          attachment.filename.includes('jpeg') ||
          attachment.filename.includes('jpg');

        return (
          <Tooltip
            key={attachment.content_id}
            parameter={{
              type: 'plain',
              position: 'top',
              description: attachment.filename,
            }}
            className={styles.attachmentTooltip}
          >
            <button
              onClick={async () => {
                try {
                  await downloadAttachment(
                    hubId,
                    threadId,
                    attachment.content_id,
                    attachment.filename,
                  );

                  startedSnack({
                    label: 'Downloaded attachment',
                    close: true,
                  });
                } catch (error) {
                  console.error(error);
                  startedSnack({
                    label: "Couldn't download attachment",
                    action: {
                      action: () => {
                        downloadAttachment(
                          hubId,
                          threadId,
                          attachment.content_id,
                          attachment.filename,
                        );
                      },
                      label: 'Try again',
                    },
                    close: true,
                  });
                }
              }}
              className={styles.attachment}
            >
              {generateAttachmentIcon(
                attachment.filename,
                attachment.content_type,
              )}
              <div className={styles.textContainer}>
                <TypographyPoppins
                  type="label"
                  labelSize="S"
                  className={styles.filename}
                >
                  {formatFileName(attachment.filename, isImage ? 10 : 12)}
                </TypographyPoppins>
                <TypographyPoppins
                  type="label"
                  labelSize="S"
                  className={styles.fileSize}
                >
                  {convertByteSize(attachment.size)}
                </TypographyPoppins>
              </div>
              {isImage ? (
                <Dropdown>
                  <Dropdown.Menu
                    trigger={
                      <div className={styles.attachmentDropdownIcon}>
                        <Icon
                          size={12}
                          color="var(--color-tertiary-40)"
                          name="sprite/chevron-down"
                        />
                      </div>
                    }
                    side="bottom"
                    align="end"
                    sideOffset={24}
                    alignOffset={-12}
                    collisionPadding={20}
                    density="-2"
                  >
                    {/* <Dropdown.MenuItem
                      key={1}
                      item={{
                        content: 'Save to this hub',
                        leadingIcon: <Icon size={14} name="sprite/hub" />,
                      }}
                    /> */}
                    <Dropdown.MenuItem
                      key="dropdown-item"
                      item={{
                        content: 'Save to library',
                        leadingIcon: <Icon size={14} name="sprite/library" />,
                        onClick: () => {
                          mutateSaveAttachmentToLibrary.mutate({
                            hubId,
                            messageId: threadId,
                            attachmentId: attachment.content_id,
                          });
                        },
                      }}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
            </button>
          </Tooltip>
        );
      })}
    </div>
  );
};

const generateAttachmentIcon = (fileName: string, fileType: string) => {
  const iconName = getFileIcon(fileName, fileType);

  if (!iconName) return null;

  return <Icon size={24} name={iconName} />;
};

const convertByteSize = (size: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let index = 0;
  let convertedSize = size;

  while (convertedSize >= 1024 && index < units.length - 1) {
    convertedSize /= 1024;
    index++;
  }

  // Round to 1 decimal place
  convertedSize = Math.round(convertedSize * 10) / 10;

  return `${convertedSize} ${units[index]}`;
};

const isHubEmail = (email: string, hubId: string) => {
  if (email === `hub_${hubId}@visualist.email`) return true;
  return false;
};
