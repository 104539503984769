import { useEffect, useState } from 'react';

import {
  generateVaiEmailActionItems,
  generateVaiEmailResponse,
  generateVaiEmailSummary,
  InfiniteConversationResponse,
  sendMessageToConversation,
  toggleMessagePin,
  triggerVaiHubStatusCheck,
  triggerVaiThreadStatusCheck,
  UserAction,
  VaiPossibleQuestion,
} from '@api/vai';
import { vaiMutationKeys } from '@src/shared/constants/mutation-keys';
import {
  vaiConversationsKeys,
  vaiPinnedMessageKeys,
} from '@src/shared/constants/query-keys';
import {
  InfiniteData,
  useIsMutating,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

export const useVaiActions = (optionalHubId?: string) => {
  const queryClient = useQueryClient();
  const hubId = optionalHubId ?? '';

  const generateHubSummaryMutation = useMutation({
    mutationKey: vaiMutationKeys.generateHubSummary(hubId),
    mutationFn: ({ hubId }: { hubId: string }) =>
      triggerVaiHubStatusCheck(hubId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: vaiConversationsKeys.hubConversation(variables.hubId),
      });
    },
  });

  const generateThreadSummaryMutation = useMutation({
    mutationKey: vaiMutationKeys.generateThreadSummary(hubId),
    mutationFn: ({ threadId }: { hubId: string; threadId: string }) =>
      triggerVaiThreadStatusCheck(threadId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: vaiConversationsKeys.hubConversation(variables.hubId),
      });
    },
  });

  const generateEmailSummaryMutation = useMutation({
    mutationKey: vaiMutationKeys.generateEmailSummary(hubId),
    mutationFn: ({ messageId }: { hubId: string; messageId: string }) =>
      generateVaiEmailSummary(messageId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: vaiConversationsKeys.hubConversation(variables.hubId),
      });
    },
  });

  const generateEmailActionItemsMutation = useMutation({
    mutationKey: vaiMutationKeys.generateEmailActionItems(hubId),
    mutationFn: ({ messageId }: { messageId: string; hubId: string }) =>
      generateVaiEmailActionItems(messageId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: vaiConversationsKeys.hubConversation(variables.hubId),
      });
    },
  });

  const generateDraftEmailResponseMutation = useMutation({
    mutationKey: vaiMutationKeys.generateDraftEmailResponse(hubId),
    mutationFn: ({
      messageId,
      tone,
    }: {
      messageId: string;
      hubId: string;
      tone?: 'CHANGE_TONE_FORMAL' | 'CHANGE_TONE_FRIENDLY';
    }) => generateVaiEmailResponse(messageId, tone),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: vaiConversationsKeys.hubConversation(variables.hubId),
      });
    },
  });

  const toggleEmailPinMutation = useMutation({
    mutationKey: vaiMutationKeys.toggleEmailPin(hubId),
    mutationFn: ({
      hubId,
      conversationId,
    }: {
      hubId: string;
      conversationId: string;
    }) => toggleMessagePin(hubId, conversationId),
    onMutate: (variables) => {
      queryClient.cancelQueries({
        queryKey: vaiConversationsKeys.hubConversation(hubId),
      });

      const originalConversation = queryClient.getQueryData<
        InfiniteData<InfiniteConversationResponse>
      >(vaiConversationsKeys.hubConversation(hubId));

      if (!originalConversation) return;

      const copiedData = {
        ...originalConversation,
        pages: originalConversation.pages.map((p) => {
          return {
            ...p,
            results: p.results.map((c) => {
              return { ...c };
            }),
          };
        }),
      } satisfies typeof originalConversation;

      // Find the message being toggled and update its pin status
      copiedData.pages = copiedData.pages.map((page) => ({
        ...page,
        results: page.results.map((conversation) => {
          if (conversation.id === variables.conversationId) {
            return {
              ...conversation,
              is_pinned: !conversation.is_pinned,
            };
          }
          return conversation;
        }),
      }));

      // Update the query data with optimistic update
      queryClient.setQueryData(
        vaiConversationsKeys.hubConversation(hubId),
        copiedData,
      );

      return { originalConversation };
    },
    onError: (e, _v, ctx) => {
      console.error(e);
      queryClient.setQueryData(
        vaiConversationsKeys.hubConversation(hubId),
        ctx?.originalConversation,
      );
    },
    onSettled: (_, _e, variables) => {
      queryClient.invalidateQueries({
        queryKey: vaiConversationsKeys.hubConversation(variables.hubId),
      });
      queryClient.invalidateQueries({
        queryKey: vaiPinnedMessageKeys.all,
      });
    },
  });

  const sendMessageMutation = useMutation({
    mutationKey: vaiMutationKeys.sendMessageToConversation(hubId),
    mutationFn: ({
      hubId,
      ...rest
    }: {
      hubId: string;
      vai_action: VaiPossibleQuestion;
      user_action: UserAction;
      relatedObjectId: string;
      relatedObjectTypeId: string;
    }) =>
      sendMessageToConversation({
        hubId,
        related_object_id: rest.relatedObjectId,
        related_object_type_id: rest.relatedObjectTypeId,
        vai_action: rest.vai_action,
        user_action: rest.user_action,
      }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: vaiConversationsKeys.hubConversation(variables.hubId),
      });
    },
  });

  return {
    generateHubSummaryMutation,
    generateThreadSummaryMutation,
    generateEmailSummaryMutation,
    generateEmailActionItemsMutation,
    generateDraftEmailResponseMutation,
    toggleEmailPinMutation,
    sendMessageMutation,
  };
};

const MINIMUM_TIME_S = 3;

export const useVaiActionProgress = (hubId: string) => {
  // Create artificial return delay so this if triggered will show true for atleast some amount of time to prevent jumping
  const [isDelayed, setIsDelayed] = useState(false);

  const isMutatingHubSummary = useIsMutating({
    mutationKey: vaiMutationKeys.generateHubSummary(hubId),
  });

  const isMutatingThreadSummary = useIsMutating({
    mutationKey: vaiMutationKeys.generateThreadSummary(hubId),
  });

  const isMutatingEmailSummary = useIsMutating({
    mutationKey: vaiMutationKeys.generateEmailSummary(hubId),
  });

  const isMutatingEmailActionItems = useIsMutating({
    mutationKey: vaiMutationKeys.generateEmailActionItems(hubId),
  });

  const isMutatingDraftEmailResponse = useIsMutating({
    mutationKey: vaiMutationKeys.generateDraftEmailResponse(hubId),
  });

  const isMutatingSendMessage = useIsMutating({
    mutationKey: vaiMutationKeys.sendMessageToConversation(hubId),
  });

  const isMutating =
    isMutatingHubSummary > 0 ||
    isMutatingThreadSummary > 0 ||
    isMutatingEmailSummary > 0 ||
    isMutatingEmailActionItems > 0 ||
    isMutatingDraftEmailResponse > 0 ||
    isMutatingSendMessage > 0;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isMutating) {
      setIsDelayed(true);
      timer = setTimeout(() => {
        setIsDelayed(false);
      }, MINIMUM_TIME_S);
    }

    return () => clearTimeout(timer);
  }, [isMutating, setIsDelayed]);

  return isMutating || isDelayed;
};
