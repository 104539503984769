import { Icon } from 'icons';
import Markdown from 'react-markdown';

import {
  Button,
  Spinner,
  TypographyPoppins,
  VaiColouredPathIcon,
} from '@visualist/design-system/src/components/v2';

import { Conversation } from '@api/vai';
import { ErrorBoundary } from '@sentry/react';
import {
  Card,
  CardContent,
  CardEmptyContent,
  CardHeader,
} from '@src/entities/cards';
import { useConversations } from '@src/widgets/vai-main/hooks/useConversations';
import { openedVaiModalWithHub } from '@src/widgets/vai-main/model';

import styles from './styles.module.css';

export const VaiCard = ({
  hubId,
  hubName,
}: {
  hubId: string;
  hubName: string;
}) => {
  const { conversationQuery } = useConversations(hubId);

  if (conversationQuery.isLoading) {
    return (
      <Card>
        <CardHeader
          title="Vai"
          icon={<VaiColouredPathIcon isPurple height={22} width={22} />}
        />
        <CardContent>
          <div className={styles.loader}>
            <Spinner />
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title="Vai"
        icon={<VaiColouredPathIcon isPurple height={22} width={22} />}
      />
      {(conversationQuery.data &&
        conversationQuery.data.conversations.length === 0) ||
      !conversationQuery.data ? (
        <CardEmptyContent
          icon={<Icon name="sprite/sparkles-vai-colored" size={80} />}
          description={`Vai is your copilot—get
											automated assistance to
											take back your time`}
          button={
            <Button
              label="Talk to Vai"
              onClick={() => {
                openedVaiModalWithHub({
                  id: hubId,
                  name: hubName,
                });
              }}
              type="outlined"
            />
          }
        />
      ) : (
        <CardContent>
          {/* Error boundary to prevent the Conversations from crashing since unsafe parse */}
          <ErrorBoundary
            fallback={<p>Sorry, it looks like something went wrong</p>}
          >
            <Conversations
              hubId={hubId}
              hubName={hubName}
              conversations={conversationQuery.data?.conversations}
            />
          </ErrorBoundary>
        </CardContent>
      )}
    </Card>
  );
};

const Conversations = ({
  conversations,
  hubId,
  hubName,
}: {
  conversations: Array<Conversation>;
  hubId: string;
  hubName: string;
}) => {
  const vaiMessages = conversations
    .filter((c) => c.convo_type.id === '0' || c.convo_type.id === '1')
    .reverse();
  const lastVaiMessage = vaiMessages[vaiMessages.length - 1];

  const actionItems = conversations.filter(
    (c) =>
      c.convo_type.id === '0' && c.vai_object_id.output_type === 'Action item',
  );
  const lastActionItem = actionItems[actionItems.length - 1];

  return (
    <div className={styles.messages}>
      {lastVaiMessage ? (
        <div className={styles.messageContainer}>
          <Icon
            name="sprite/chat-left"
            style={{
              flexShrink: 0,
            }}
          />
          <div>
            <TypographyPoppins
              type="body"
              bodySize="M"
              className={styles.marginBottom}
            >
              Continue your chat with Vai
            </TypographyPoppins>
            <button
              className={styles.message}
              onClick={() => {
                openedVaiModalWithHub({
                  id: hubId,
                  name: hubName,
                });
              }}
            >
              <TypographyPoppins type="body" bodySize="M">
                <Markdown className={styles.leftAlign}>
                  {'text' in lastVaiMessage ? lastVaiMessage.text.text : ''}
                </Markdown>
              </TypographyPoppins>
            </button>
          </div>
        </div>
      ) : null}

      {lastActionItem && 'text' in lastActionItem ? (
        <div className={styles.messageContainer}>
          <Icon name="sprite/checkbox" />
          <div>
            <TypographyPoppins
              type="body"
              bodySize="M"
              className={styles.marginBottom}
            >
              Vai recently created this action item:{' '}
              {/* TODO REFACTOR ASAP WHEN CAN */}
              {lastActionItem.text.text}
            </TypographyPoppins>
          </div>
        </div>
      ) : null}
    </div>
  );
};
