import { useUnit } from 'effector-react';

import { $selectedTab } from '../../model';
import { VaiChat } from '../vai-chat';

import styles from './styles.module.css';

export const VaiTabRenderer = () => {
  const [selectedTab] = useUnit([$selectedTab]);

  return (
    <div className={styles.content}>
      {(() => {
        switch (selectedTab) {
          case 'chat':
            return <VaiChat />;
          case 'personalize':
            return <div>Personalise Content</div>;
          case 'aesthetiq':
            return <div>AesthetIQ Content</div>;
          default:
            return null;
        }
      })()}
    </div>
  );
};
