import { forwardRef } from 'react';

import cn from 'classnames';
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  PropsWithChildren,
} from 'react';

import {
  TypographyPoppins,
  VaiTidyUpGradient,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

type ButtonProps = ComponentPropsWithoutRef<'button'> & {
  variant?: 'primary' | 'secondary' | 'ghost';
  isLoading?: boolean;
  isDisabled?: boolean;
};

const VaiButton = forwardRef<ElementRef<'button'>, ButtonProps>(
  (
    {
      variant = 'primary',
      isLoading = false,
      isDisabled = false,
      children,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        disabled={isDisabled || isLoading}
        className={cn(styles.button, className, {
          [styles.primary]: variant === 'primary',
          [styles.secondary]: variant === 'secondary',
          [styles.ghost]: variant === 'ghost',
        })}
        {...props}
      >
        {/* {isLoading ? (
          <span>Loading...</span>
        ) : (
          children
        )} */}
        {children}
      </button>
    );
  },
);

VaiButton.displayName = 'VaiButton';

const VaiButtonText = ({ children }: PropsWithChildren) => {
  return (
    <TypographyPoppins type="label" labelSize="L">
      {children}
    </TypographyPoppins>
  );
};

VaiButtonText.displayName = 'VaiButtonText';

const VaiButtonIcon = () => {
  return <VaiTidyUpGradient />;
};

VaiButtonIcon.displayName = 'VaiButtonIcon';

export { VaiButton, VaiButtonText, VaiButtonIcon };
