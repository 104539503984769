import { createEvent, createStore } from 'effector';

export type VaiTab = 'chat' | 'personalize' | 'aesthetiq';

export const openedVaiModal = createEvent();
export const openedVaiModalWithHub = createEvent<{
  id: string;
  name: string;
} | null>();
export const openedVaiModalWithMessage = createEvent<string>();
export const scrolledMessageIntoView = createEvent();
export const closedVaiModal = createEvent();

export const changedVaiTabs = createEvent<VaiTab>();

export const changedVaiHubWorkspace = createEvent<{
  id: string;
  name: string;
} | null>();

export const $showVaiModal = createStore<boolean>(false)
  .on(openedVaiModal, () => true)
  .on(openedVaiModalWithHub, () => true)
  .on(openedVaiModalWithMessage, () => true)
  .reset(closedVaiModal);

export const $selectedTab = createStore<VaiTab>('chat').on(
  changedVaiTabs,
  (_, tab) => tab,
);

export const $selectedHubWorkspace = createStore<{
  id: string;
  name: string;
} | null>(null)
  .on(openedVaiModal, () => null)
  .on(changedVaiHubWorkspace, (_, workspace) => workspace)
  .on(openedVaiModalWithHub, (_, hub) => hub)
  .reset(closedVaiModal);

export const $vaiModalSelectedMessage = createStore<string | null>(null)
  .on(openedVaiModal, () => null)
  .on(openedVaiModalWithMessage, (_, id) => id)
  .reset([scrolledMessageIntoView, closedVaiModal]);
