import { useState } from 'react';
import { ReactNode } from 'react';

import cn from 'classnames';
import { Icon } from 'icons';

import {
  TypographyPoppins,
  VaiColored,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const VaiSectionAccordion = ({ children }: { children: ReactNode }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div
        className={cn(styles.vaiHeaderContainer, {
          [styles.vaiHeaderExpandedContainer]: expanded,
        })}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <VaiColored fill="none" />
        <TypographyPoppins
          type="title"
          titleSize="M"
          className={styles.vaiHeaderTitle}
        >
          Vai
        </TypographyPoppins>
        <Icon
          name={expanded ? 'sprite/chevron-up' : 'sprite/chevron-down'}
          size={24}
          className={styles.marginLeftAuto}
        />
      </div>
      {expanded && <div className={styles.vaiCardContainer}>{children}</div>}
    </>
  );
};
