import { useUnit } from 'effector-react';
import { Icon } from 'icons';

import {
  AssistChip,
  Spinner,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { ErrorBoundary } from '@sentry/react';
import { HubSearch } from '@src/entities/search-modal/hub-search';

import { useVaiActions } from '../../hooks/useVaiActions';
import { $selectedHubWorkspace, changedVaiHubWorkspace } from '../../model';
import { Conversations } from '../message';
import { VaiHubSwitcher } from '../vai-hub-switcher';

import styles from './styles.module.css';

export const VaiChat = () => {
  const [selectedHubWorkspace] = useUnit([$selectedHubWorkspace]);

  if (!selectedHubWorkspace) {
    return <EmptyState />;
  }

  return <Chat hubId={selectedHubWorkspace.id} />;
};

export const Chat = ({ hubId }: { hubId: string }) => {
  return (
    <div className={styles.container}>
      <ErrorBoundary
        fallback={() => <div className={styles.empty}>An error occurred</div>}
      >
        <Conversations hubId={hubId} />
      </ErrorBoundary>
      <VaiHubSwitcher />
    </div>
  );
};

const EmptyState = () => {
  return (
    <div className={styles.container}>
      <EmptyScreen />
      <VaiHubSwitcher />
    </div>
  );
};

export const EmptyScreen = ({ selectedHubId }: { selectedHubId?: string }) => {
  const { generateHubSummaryMutation } = useVaiActions(selectedHubId);

  if (generateHubSummaryMutation.isPending) {
    return (
      <div className={styles.empty}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.empty}>
      <div className={styles.sparkleHeader}>
        <Icon name="sprite/sparkles-colored" size={80} />
        <TypographyPoppins
          type="body"
          bodySize="S"
          className={styles.helpWithText}
        >
          What can Vai help with?
        </TypographyPoppins>
      </div>
      <div className={styles.buttons}>
        <div className={styles.row}>
          {typeof selectedHubId === 'undefined' ? (
            <HubSearch
              onResultClick={(hub) => {
                generateHubSummaryMutation.mutate({
                  hubId: hub.id,
                });
                changedVaiHubWorkspace({
                  id: hub.id,
                  name: hub.name,
                });
              }}
            >
              <AssistChip
                icon={
                  <Icon
                    name="sprite/action-items-list"
                    color="var(--color-neutral-10)"
                  />
                }
                leadingIcon
              >
                Get project update
              </AssistChip>
            </HubSearch>
          ) : (
            <AssistChip
              icon={
                <Icon
                  name="sprite/action-items-list"
                  color="var(--color-neutral-10)"
                />
              }
              leadingIcon
              onClick={async () => {
                await generateHubSummaryMutation.mutateAsync({
                  hubId: selectedHubId,
                });
              }}
            >
              Get project update
            </AssistChip>
          )}
          {/* <AssistChip>Generate palette</AssistChip> */}
        </div>
        {/* <div className={styles.row}>
          <AssistChip>Improve a doc</AssistChip>
          <AssistChip>Create new design</AssistChip>
          <AssistChip>Draft proposal</AssistChip>
        </div> */}
      </div>
    </div>
  );
};
