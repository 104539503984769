import { api } from '.';
import { HubResponse } from './hubs';

/*
 * (0, 'vai_generated_output', 'Generated output from VAI')
 * (1, 'vai_questions', 'VAI predefined message')
 * (2, 'user_message', 'User message')
 * (3, 'undefined', 'Undefined')
 */

type ConversationTypeWithText =
  | {
      id: '0';
      text: 'Generated output from VAI';
    }
  | {
      id: '1';
      text: 'VAI predefined message';
    }
  | {
      id: '2';
      text: 'User message';
    };

type ConversationTypeWithoutText = {
  id: '3';
  text: 'Undefined';
};

export type RelatedObject = {
  type_model: 'hub' | 'message';
  type_id: string;
  id: string;
};

export type VaiPossibleQuestion =
  | 'DRAFT_RESPONSE_PROMPT'
  | 'ADD_TO_LIST_PROMPT'
  | 'EXTRACT_ACTION_ITEMS_PROMPT'
  | 'RATING_PROMPT'
  | 'FURTHER_ACTIONS_PROMPT'
  | 'ACTION_FOUND_ADD_PROMPT';
export type UserAction =
  | 'SUMMARIZE'
  | 'DRAFT_RESPONSE'
  | 'CREATE_ACTION_ITEMS'
  | 'HUB_STATUS_CHECK'
  | 'THREAD_STATUS_CHECK'
  | 'DONE'
  | 'YES'
  | 'NO'
  | 'CHANGE_TONE_FORMAL'
  | 'CHANGE_TONE_FRIENDLY';

type ConversationPartial = {
  createdAt: string;
  hub: Pick<HubResponse, 'id' | 'name' | 'thumbnail' | 'created_by'>;
  id: string;
  is_pinned: boolean;
  possible_responses: Array<{
    response_text: string;
    action: UserAction;
  }>;
  related_object: RelatedObject;
  vai_object_id: {
    id: string;
    output_type: string;
  };
};

export type VaiUserConversation = ConversationPartial & {
  text: {
    text: string;
    action: VaiPossibleQuestion;
  };
  convo_type: ConversationTypeWithText;
};

export type UndefinedConversation = ConversationPartial & {
  convo_type: ConversationTypeWithoutText;
};

export type Conversation = VaiUserConversation | UndefinedConversation;

export type InfiniteConversationResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<Conversation>;
};

/**
 * To trigger VAI to create a status check of the hub (it is a summary of all the available threads in the hub). It will return with a 201 HTTP Response
 * TODO Update for standard reponse
 * @param hubId
 * @returns
 */

export const triggerVaiHubStatusCheck = async (
  hubId: string,
): Promise<unknown> => {
  await new Promise<void>((res) => {
    setTimeout(() => res(), 10000);
  });
  return api.post(`/vai/hub/${hubId}/status-check/`);
};

/**
 * To trigger VAI to create a status check of the thread (it is a summary of all the emails in the thread). It will return with a 201 HTTP Response
 * TODO Update for standard reponse
 * @param threadId
 * @returns
 */

export const triggerVaiThreadStatusCheck = async (
  threadId: string,
): Promise<unknown> => {
  return api.post(`/vai/thread/${threadId}/status-check/`);
};

/**
 * To trigger VAI to generate summary of an email. It will return with a 201 HTTP Response
 * TODO Update for standard reponse
 * @param messageId
 * @returns
 */

export const generateVaiEmailSummary = async (
  messageId: string,
): Promise<unknown> => {
  await new Promise<void>((res) => {
    setTimeout(() => res(), 10000);
  });
  return api.post(`/vai/message/${messageId}/summary/`);
};

/**
 * To trigger VAI to generate action items of an email. It will return with a 201 HTTP Response
 * TODO Update for standard reponse
 * @param messageId
 * @returns
 */

export const generateVaiEmailActionItems = async (
  messageId: string,
): Promise<unknown> => {
  return api.post(`/vai/message/${messageId}/action-item/`);
};

/**
 * To trigger VAI to generate a draft response to an email. It will return with a 201 HTTP Response
 * TODO Update for standard reponse
 * @param messageId
 * @returns
 */

export const generateVaiEmailResponse = async (
  messageId: string,
  tone?: 'CHANGE_TONE_FORMAL' | 'CHANGE_TONE_FRIENDLY',
): Promise<unknown> => {
  return api.post(`/vai/message/${messageId}/response/`, {
    tone,
  });
};

/**
 * To fetch conversations in a hub. It will return with a 201 HTTP Response. It returns the "chat" ordered by latest first.
 * TODO Update for standard reponse
 * @param hubId
 * @returns
 * (0, 'vai_generated_output', 'Generated output from VAI')
 * (1, 'vai_questions', 'VAI predefined message')
 * (2, 'user_message', 'User message')
 * (3, 'undefined', 'Undefined')
 *
 * 0 and 1 are VAI speaking messages
 * 3 are user speaking messages
 * Attached to type 1 vai_questions  I provide also possible user responses for the user to choose from.
 */

export const getConversations = async ({
  hubId,
  limit = 40,
  offset,
}: {
  hubId: string;
  offset?: number;
  limit?: number;
}): Promise<InfiniteConversationResponse> => {
  const { data } = await api.get(`/vai/conversation/hub/${hubId}/`, {
    params: {
      limit,
      offset,
    },
  });
  return data;
};

/**
 * Sends a user's selected response to a conversation in a hub
 * @param hubId - The ID of the hub containing the conversation
 * @param vai_action - The VAI question/prompt being responded to
 * @param user_action - The user's selected response action
 * @param relatedObjectId - ID of the related object (e.g. message, task)
 * @param relatedObjectTypeId - Type of the related object
 * @returns Promise that resolves when the message is sent
 */

export const sendMessageToConversation = async ({
  hubId,
  ...rest
}: {
  hubId: string;
  vai_action: VaiPossibleQuestion;
  user_action: UserAction;
  related_object_id: string;
  related_object_type_id: string;
}): Promise<unknown> => {
  return api.post(`/vai/conversation/hub/${hubId}/message/`, {
    ...rest,
  });
};

/**
 * Get Pinned messages
 */

type PinnedMessage = {
  id: string;
  vai_object_id: {
    id: string;
    output_type: string;
  };
  text: {
    text: string;
  };
};

export const getPinnedMessages = async (
  hubId: string,
): Promise<PinnedMessage[]> => {
  const { data } = await api.get(`/vai/conversation/hub/${hubId}/pinned/`);
  return data;
};

/**
 * Pin or unpin a message
 */

export const toggleMessagePin = async (
  hubId: string,
  conversationId: string,
): Promise<unknown> => {
  return api.post(`/vai/conversation/hub/${hubId}/${conversationId}/pin/`);
};

// Popup dismissalls
/**
 * @description Sends a request to the API to never ask for the tagging modal WS ping again
 */

export const doNotAskAgainTaggingModal = async (
  type: 'tagging_prompt' | (string & {}),
) => {
  const { data } = await api.post('/connections/vai/do_not_ask_again/', {
    type,
  });
  return data;
};
