import { Icon } from 'icons';

import {
  ChatSpeechRight,
  Dropdown,
  VaiColored,
} from '@visualist/design-system/src/components/v2';

import { VaiBeacon } from '@src/entities/vai/ui/beacon';
import { useVaiActions } from '@src/widgets/vai-main/hooks/useVaiActions';
import { openedVaiModalWithHub } from '@src/widgets/vai-main/model';

import styles from './styles.module.css';

export const VaiMessageBeacon = ({
  threadId,
  hubId,
  hubName,
}: {
  threadId: string;
  parentThreadId: string;
  hubId: string;
  hubName: string;
}) => {
  const {
    generateEmailActionItemsMutation,
    generateDraftEmailResponseMutation,
    generateEmailSummaryMutation,
  } = useVaiActions(hubId);

  const openVaiModalAndSwitchHub = () => {
    openedVaiModalWithHub({
      id: hubId,
      name: hubName,
    });
  };

  const handleSummarize = async () => {
    openVaiModalAndSwitchHub();
    await generateEmailSummaryMutation.mutateAsync({
      messageId: threadId,
      hubId,
    });
  };

  const handleActionItems = async () => {
    openVaiModalAndSwitchHub();
    await generateEmailActionItemsMutation.mutateAsync({
      messageId: threadId,
      hubId,
    });
  };

  const handleDraftResponse = async () => {
    openVaiModalAndSwitchHub();
    await generateDraftEmailResponseMutation.mutateAsync({
      messageId: threadId,
      hubId,
    });
  };

  return (
    <div
      style={{
        marginBottom: '-2.5px',
      }}
    >
      <Dropdown>
        <Dropdown.Menu
          side="bottom"
          align="start"
          density="-2"
          trigger={<VaiBeacon />}
        >
          <Dropdown.MenuItem
            item={{
              content: 'Ask Vai to',
              leadingIcon: <VaiColored fill="none" />,
              isDivider: true,
              isDisabled: true,
              classNameContent: styles.vaiDropdownHeader,
            }}
            style={{ cursor: 'default' }}
          />
          <Dropdown.MenuItem
            item={{
              content: 'Summarize message',
              leadingIcon: <ChatSpeechRight fill="none" />,
              onClick: handleSummarize,
            }}
          />
          <Dropdown.MenuItem
            item={{
              content: 'Create action items',
              leadingIcon: <Icon name="sprite/task" />,
              onClick: handleActionItems,
            }}
          />
          <Dropdown.MenuItem
            item={{
              content: 'Draft response',
              leadingIcon: <Icon name="sprite/contract" />,
              onClick: handleDraftResponse,
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
