import { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useUnit } from 'effector-react';
import Masonry from 'react-masonry-css';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router';

import { useEscape } from '@visualist/hooks';

import { LibraryActions } from '@components/LibraryActions';
import { PageLoader } from '@components/PageLoader';
import { locationSelected } from '@src/entities/dialogs/board/creation/search/model';
import { $isShowBoardDialog } from '@src/entities/dialogs/board/model';
import { SearchDialog } from '@src/entities/search/ui/searchDialog';
import { RECENT_HUBS_KEY } from '@src/shared/constants/variables-local-storage';
import { useRecent } from '@src/shared/hooks/useRecent';
import { useBilling } from '@src/shared/queries/useBilling';
import { useQuery } from '@tanstack/react-query';

import {
  getBoardsOfHub,
  getHub,
  getInviteesHub,
  PermissionsHub,
  UserInvitationHub,
} from '../../api/hubs';
import { getShare } from '../../api/share';
import { useAppData } from '../../AppContext';
import { DocumentHead } from '../../components/DocumentHead';
import { useInviteToHub } from '../../pages/HubPage/model/queries/useInviteToHub';
import {
  BOARDS_OF_HUB_QUERY,
  HUB_QUERY,
  MEMBERS_INVITATION_HUB_QUERY,
} from '../../shared/constants';
import { FileCard } from '..';
import { HubActionItems } from './components/ActionItems';
import { Boards } from './components/Boards';
import { Container } from './components/Container';
import { Details } from './components/Details';
import { Header } from './components/Header';
import {
  colorPicked,
  currentHeaderNameSetted,
  thumbnailHexSetted,
  thumbnailSetted,
} from './components/Header/model';
import { LockedView } from './components/LockedView';
import { HubMessageCenter } from './components/MessageCenter';
import { Settings } from './components/Settings';
import { SettingSheet } from './components/SettingsSheet';
import { HubShareSheet } from './components/ShareSheet';
import { VaiCard } from './components/VaiCard';
import { Welcome } from './components/Welcome';
import {
  $hubPermissionsDropdown,
  $isOpenHubPersmissionsDeleteModal,
  deleteHubPersmissionsModalClosed,
  hubPermissionsDropdownClosed,
  shareSideSheetClosed,
} from './model';
import { useHubActions } from './model/queries/useHubActions';
import { useHubMessages } from './model/queries/useHubMessages';

import styles from './styles.module.css';

const cardsBreakpoints = {
  default: 3,
  1300: 3,
  1279: 2,
  767: 1,
};

export type HubShare = {
  hub: string;
  id: string;
  link: string;
  allow_comments: boolean;
};

export const HubPage = () => {
  const [name, setName] = useState('');
  const [hubShare, setHubShare] = useState<HubShare | null>(null);
  const [users, setUsers] = useState<UserInvitationHub[]>([]);
  const [invitation, setInvitation] = useState<UserInvitationHub>({
    email: '',
  });
  const [message, setMessage] = useState('');
  const [permissions, setPermissions] = useState<PermissionsHub[]>([
    'edit',
    'invite',
  ]);

  const hubPermissionsDropdown = useUnit($hubPermissionsDropdown);
  const isOpenHubPersmissionsDeleteModal = useUnit(
    $isOpenHubPersmissionsDeleteModal,
  );
  const isShowBoardDialog = useUnit($isShowBoardDialog);

  const { hub_id } = useParams<{ hub_id: string }>();

  const { user } = useAppData();
  const history = useHistory();

  const isMedium = useMediaQuery({
    query: `(min-width: 767px) and (max-width: 1279px)`,
  });

  const {
    data: hub,
    isLoading: isLoadingHub,
    isError: isHubError,
    error: hubError,
  } = useQuery({
    queryKey: [HUB_QUERY, { hubId: hub_id }],
    queryFn: () => getHub({ hubId: hub_id }),
  });

  const { query: billingQuery, isWithinThreeDays } = useBilling();

  const getDateOfBilling = (isSettingsSheet?: boolean) => {
    if (billingQuery.data && billingQuery.data.current_membership_period_end) {
      const date = format(
        billingQuery.data.current_membership_period_end * 1000,
        'MMMM d, yyyy',
      );
      const month = date.split(' ')[0];
      const number = date.split(' ')[1];
      const year = date.split(' ')[2];

      return isSettingsSheet
        ? `Your next bill is on ${month} ${number} ${year}.`
        : `Your next bill is on ${month} ${number} ${year}`;
    }

    return null;
  };

  const { data: boards, isLoading: isLoadingBoards } = useQuery({
    queryKey: [BOARDS_OF_HUB_QUERY, { hub: hub_id }],
    queryFn: () => getBoardsOfHub(hub_id),
  });

  const boardsWithRightAccess = boards?.filter(
    (board) => board.permissions.length > 0,
  );

  const { data: invitees } = useQuery({
    queryKey: [MEMBERS_INVITATION_HUB_QUERY, { hub_id }],
    queryFn: () => getInviteesHub(hub_id),
  });

  const isOwner = invitees?.members?.find(
    (member) => member.user?.id === user.id,
  )?.is_owner;

  const { items } = useHubActions({ id: hub_id });
  const { messagesQuery } = useHubMessages({ id: hub_id });

  useEffect(() => {
    if (hub) {
      currentHeaderNameSetted(hub.name);
      thumbnailSetted(hub.thumbnail);
      thumbnailHexSetted(hub.thumbnail_hex);
    }
  }, [hub]);

  useEffect(() => {
    const getShareFromId = async (shareId: string) => {
      const hubShare = await getShare(shareId);
      setHubShare(hubShare);
    };
    if (hub && hub?.share?.id) {
      getShareFromId(hub.share.id);
    } else {
      setHubShare(null);
    }
  }, [hub]);

  const handleSuccessInviteHub = () => {
    setUsers([]);
    setInvitation({ email: '' });
    setMessage('');
  };

  const { sendInvitationHub } = useInviteToHub({
    onSuccess: handleSuccessInviteHub,
  });

  useEffect(() => {
    if (hub) {
      setName(hub.name);
      colorPicked(hub.thumbnail_hex);
    }
  }, [hub]);

  useRecent({
    key: RECENT_HUBS_KEY,
    item: {
      id: hub_id,
      name: hub?.name ?? '',
    },
  });

  useEffect(() => {
    if (!users.length && !invitation.email.length) {
      setPermissions(['edit', 'invite']);
    }
  }, [users.length, invitation.email.length]);

  useEffect(() => {
    if (isShowBoardDialog && hub) {
      locationSelected({
        id: hub.id,
        name: hub.name,
        type: 'hub',
      });
    }
  }, [hub, isShowBoardDialog]);
  const handleSendInviteHub = () => {
    sendInvitationHub({
      hubId: hub_id,
      users,
      message,
      permissions,
    });
  };

  useEscape(() => {
    if (isOpenHubPersmissionsDeleteModal) {
      deleteHubPersmissionsModalClosed();
    } else if (hubPermissionsDropdown) {
      hubPermissionsDropdownClosed();
    } else {
      closeShareModal();
    }
  });

  const closeShareModal = () => {
    shareSideSheetClosed();
    setUsers([]);
    setInvitation({ email: '' });
    setMessage('');
  };

  if (isHubError && hubError.message.includes('404')) {
    history.push('/error');
  }

  if (!hub) return null;

  if (isLoadingHub || isLoadingBoards || billingQuery.isLoading)
    return <PageLoader />;

  return (
    <>
      <SearchDialog />
      <FileCard />
      <div className={styles.hubPage}>
        <Container>
          <DocumentHead name={name} />
          <Header
            thumbnail={hub.thumbnail && hub.thumbnail.full_size}
            thumbnailHex={hub.thumbnail_hex}
            name={name}
            hubId={hub.id}
            thumbnailBrightness={hub.thumbnail_brightness}
          />
          <div className={styles.innerContainer}>
            {isMedium ? (
              <Masonry
                breakpointCols={cardsBreakpoints}
                className={styles.cards}
                columnClassName={styles.cardsColumn}
              >
                <Welcome title={hub.welcome_title} body={hub.welcome_message} />
                <Details
                  description={hub.description}
                  location={hub.project_details?.location}
                />
                <HubActionItems items={items.data} hub={hub} />
                <HubMessageCenter messages={messagesQuery.data} hub={hub} />
                <VaiCard hubId={hub.id} hubName={hub.name} />
                <Settings
                  hubShare={hubShare}
                  getDateOfBilling={getDateOfBilling}
                  isOwner={isOwner}
                />

                {/* <LatestItems /> */}
              </Masonry>
            ) : (
              <Masonry
                breakpointCols={cardsBreakpoints}
                className={styles.cards}
                columnClassName={styles.cardsColumn}
              >
                <Welcome title={hub.welcome_title} body={hub.welcome_message} />
                <Details
                  description={hub.description}
                  location={hub.project_details?.location}
                />
                <Settings
                  hubShare={hubShare}
                  getDateOfBilling={getDateOfBilling}
                  isOwner={isOwner}
                />
                <HubActionItems items={items.data} hub={hub} />
                <HubMessageCenter messages={messagesQuery.data} hub={hub} />
                {/* <LatestItems /> */}
              </Masonry>
            )}

            <Boards boards={boardsWithRightAccess} hubId={hub_id} />
            {hub.is_locked && billingQuery.data ? (
              <LockedView
                isLocked={hub.is_locked}
                currentPlan={billingQuery.data.membershipTierInfo.plan}
                isOwner={typeof isOwner === 'undefined' ? false : isOwner}
              />
            ) : null}
            {hub.is_locked && billingQuery.data ? (
              <LockedView
                isLocked={hub.is_locked}
                currentPlan={billingQuery.data.membershipTierInfo.plan}
                isOwner={typeof isOwner === 'undefined' ? false : isOwner}
              />
            ) : null}
          </div>
        </Container>
        <HubShareSheet
          hubId={hub_id}
          users={users}
          setUsers={setUsers}
          invitation={invitation}
          setInvitation={setInvitation}
          handleSendInviteHub={handleSendInviteHub}
          message={message}
          setMessage={setMessage}
          permissions={permissions}
          setPermissions={setPermissions}
          hubName={name}
        />
        <SettingSheet
          hubShare={hubShare}
          setHubShare={setHubShare}
          hubId={hub_id}
          hubName={hub.name}
          isWithinThreeDays={isWithinThreeDays}
          getDateOfBilling={getDateOfBilling}
          isOwner={isOwner}
        />
        <LibraryActions isHubLocked={hub.is_locked} />
      </div>
    </>
  );
};
