import { getHubActions } from '@api/actions';
import { actions } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

export const useHubActions = ({ id }: { id: string }) => {
  const items = useQuery({
    queryKey: actions.action(id),
    queryFn: () => getHubActions({ id: id }),
  });

  return {
    items,
  };
};
